import React from "react";
import "../styles/banner.css";

const RunBanner = () => {
  return (
    <div className="running-text-container">
      <div className="running-text">
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
        Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi Pipi
      </div>
    </div>
  );
};

export default RunBanner;

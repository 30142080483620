import axios from "axios";

export const callApi = async (method, url, data) => {
  const params = {
    discord_id: data.discord_id,
    score: data.score,
    wallet: data.wallet,
  };
  try {
    const response = await axios({
      method: method,
      url: url,
      data: params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const callApiCheckWallet = async (method, url, params) => {
  try {
    const response = await axios({
      method: method,
      url: url,
      data: params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

import React from "react";
import "../styles/socials.css";
import "../App.css";

const Social = () => {
  return (
    <div className="container-page social-page">
      <div className="social-header">
        <p>Join the Pipi City community</p>
        <div className="social-links">
          <a
            href="https://twitter.com/pipicitynft"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/twitter.png" alt="twitter"></img>
          </a>
          <a
            href="https://discord.com/invite/pipicity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/discord.png" alt="discord"></img>
          </a>
        </div>
      </div>

      <div className="social-content">
        <img src="/assets/images/socials.gif" alt="social"></img>
        <div className="text-animation">Pipi City</div>
      </div>
    </div>
  );
};

export default Social;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../utils/connectors";
import { switchChain } from "../utils/switchChain";
import config from "../config";
import "../index.css";
import "../game.css";
import axios from "axios";
import { formatAddress, formatScore } from "../utils/helpers";
import ConnectMetamasks from "../components/ConnectMetamasks";
import Menu from "../components/Menu";

const HomeGame = () => {
  const { account, activate, deactivate, active } = useWeb3React();
  const [address, setUserAddress] = useState(null);
  const [data, setData] = useState([]);
  const [discord, setDiscord] = useState(null);
  const [highlightIndex, setHighlightIndex] = useState(null);
  const navigate = useNavigate();
  //window.ethereum

  useEffect(() => {
    const init = async () => {
      await connectWallet();
    };
    if (!active) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account) {
      setUserAddress(account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const connectWallet = async () => {
    await activate(
      injected,
      async (error) => {
        const network = config.unsupportedChainSetup[config.chainId];
        const hasSetup = await switchChain(
          network ?? {
            chainId: `0x${parseInt(config.chainId.toString()).toString(16)}`,
          }
        );
        if (hasSetup) {
          await activate(injected, async () => {
            console.log(`${error.message}`);
          });
        } else {
          alert(`Unable to connect to required network ${config.chainId}`);
        }
      },
      false
    );
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_END_POINT}/get-score`)
      .then((response) => {
        setData(response.data.data);
        const index = data.findIndex((item) => item.wallet === address);
        setHighlightIndex(index);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, address]);

  useEffect(() => {
    if (address) {
      axios
        .get(`${process.env.REACT_APP_END_POINT}/get-discord/${address}`)
        .then((response) => {
          setDiscord(response.data.data.discord_id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [address]);

  const handlePlay = () => {
    if (address != null || address !== "") {
      if (discord && discord != null && discord !== "") {
        const discordURL = encodeURIComponent(discord);
        navigate(`/play?wallet=${address}&discordID=${discordURL}`);
      } else {
        alert("Please enter DiscordID");
      }
    } else {
      alert("You need to connect your wallet address to play.");
    }
  };

  const handleChange = (event) => {
    setDiscord(event.target.value);
  };

  return (
    <div className="home-game">
      <Menu></Menu>
      {/* <div className="logo">PiPi City</div> */}
      <div className="connect-wl">
        <ConnectMetamasks
          userAddress={address}
          isWalletConnected={active}
          connectWallet={() => connectWallet()}
          disconnectWallet={() => deactivate()}
        />
      </div>

      <div className="social-link">
        <a
          href="https://twitter.com/pipicitynft"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/assets/images/twitter.png" alt="twitter"></img>
        </a>

        <a
          href="https://discord.com/invite/pipicity"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/assets/images/discord.png" alt="discord"></img>
        </a>
      </div>
      <div className="container-game">
        <div className="content-game">
          <img src="/assets/images/home-game.gif" alt="gift game"></img>

          <form>
            <div className="discordInput">
              <input
                type="text"
                placeholder="Enter your DiscordID"
                value={discord || ""}
                onChange={handleChange}
              />

              <button
                type="submit"
                className="testbutton play-game"
                onClick={handlePlay}
              >
                Play Game
              </button>
            </div>
          </form>
          <p>
            If you're a gaming champ, why not test your skills with Pipi City's
            gold rush game? The top 50 holders will score an Airdrop of $PIPI
            based on their highest points. Let's see how much $PIPI you can rake
            in! 🎮💰
          </p>
        </div>

        <div className="leader-board">
          <h1 className="top-100">Top 100</h1>
          <div>
            <div className="table-rank">
              <table className="border-collapse ">
                <thead>
                  <tr>
                    <th className="p-2 uppercase">RANK</th>
                    <th className="p-2 uppercase ">WALLET</th>
                    <th className="p-2 uppercase ">$PIPI</th>
                    <th className="p-2 uppercase ">TIMES</th>
                    <th className="p-2 uppercase ">DISCORD ID</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr
                        key={index}
                        className={
                          highlightIndex === index ? "highlighted-row" : ""
                        }
                      >
                        <td className="p-2  uppercase  px-10">{index + 1}</td>

                        <td className="wallet-lb p-2">
                          {item.wallet && formatAddress(item.wallet, 4)}
                        </td>
                        <td className="p-2  px-7 text-[#468ccf] max-w-[300px]">
                          {formatScore(item.score)}
                        </td>
                        <td className="p-2  px-7 text-[#468ccf] max-w-[300px]">
                          {item.play_count}
                        </td>
                        <td className="p-2  px-7 text-[#468ccf] max-w-[700px]">
                          {item.discord_id}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeGame;

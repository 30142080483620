import React from "react";
import Menu from "../components/Menu";
import RunBanner from "../components/RunBanner";
import "../styles/story.css";

const Story = () => {
  return (
    <div className="containe-home">
      <Menu></Menu>
      <div className="story">
        <img src="/assets/story/story1.webp" alt="story1"></img>
        <RunBanner></RunBanner>
        <img src="/assets/story/story2.webp" alt="story2"></img>
        <RunBanner></RunBanner>
        <img src="/assets/story/story3.webp" alt="story3"></img>
        <RunBanner></RunBanner>
        <img src="/assets/story/story4.webp" alt="story4"></img>
        <RunBanner></RunBanner>
        <img src="/assets/story/story5.webp" alt="story5"></img>
        <RunBanner></RunBanner>
      </div>
      <div className="storym">
        <img src="/assets/story/story1_m.webp" alt="story1"></img>
        <RunBanner></RunBanner>
        <img src="/assets/story/story2_m.webp" alt="story2"></img>
        <RunBanner></RunBanner>
        <img src="/assets/story/story3_m.webp" alt="story3"></img>
        <RunBanner></RunBanner>
        <img src="/assets/story/story4_m.webp" alt="story4"></img>
        <RunBanner></RunBanner>
        <img src="/assets/story/story5_m.webp" alt="story5"></img>
        <RunBanner></RunBanner>
      </div>
    </div>
  );
};

export default Story;

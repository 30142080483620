/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../utils/connectors";
import { switchChain } from "../../utils/switchChain";
import { getContractProvider } from "../../utils/getContractProvider";
import config from "../../config";
import whitelist from "../../utils/allowlist";
import MintWidget from "./MintWidget";
import Loading from "../Loading";

const contractAbi = require("../../contracts/abi.abi.json");
const networks = {
  base: {
    chainId: `0x${Number(8453).toString(16)}`,
    chainName: "Base Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.base.org/"],
    blockExplorerUrls: ["https://basescan.org/"],
  },
  basetestnet: {
    chainId: `0x${Number(84532).toString(16)}`,
    chainName: "Base Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://sepolia.base.org/"],
    blockExplorerUrls: ["https://sepolia-explorer.base.org/"],
  },
};
const changeNetwork = async ({ networkName, setError }) => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...networks[networkName],
        },
      ],
    });
  } catch (err) {
    setError(err.message);
    alert(err.message);
  }
};

const MintTemplate = () => {
  const { account, activate, deactivate, active, library } = useWeb3React();
  const [contract, setContract] = useState();
  const [userAddress, setUserAddress] = useState(null);
  const [wlPrice, setWlPrice] = useState(BigNumber.from(0));
  const [salePrice, setSalePrice] = useState(BigNumber.from(0));
  const [balance, setBalance] = useState(BigNumber.from(0));
  const [maxFree, setMaxFree] = useState(0);
  const [maxWLTx, setMaxWLTx] = useState(0);
  const [maxTx, setMaxTx] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [saleState, setSaleState] = useState(0);
  const [freeMinted, setFreeMinted] = useState(0);
  const [claimed, setClaimed] = useState(0);
  const [isUserInWhitelist, setIsUserInWhitelist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [mintSuccess, setMintSuccess] = useState(false);

  const [error, setError] = useState();

  const handleNetworkSwitch = async (networkName) => {
    setError();
    await changeNetwork({ networkName, setError });
    console.log(error);
  };

  const networkChanged = (chainId) => {
    console.log({ chainId });
  };

  useEffect(() => {
    window.ethereum.on("chainChanged", networkChanged);

    return () => {
      window.ethereum.removeListener("chainChanged", networkChanged);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      setContract(await getContractProvider());
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      await connectWallet();
    };
    if (!active) {
      init();
    }
  }, []);

  useEffect(() => {
    if (account) {
      setUserAddress(account);
    }
  }, [account]);

  useEffect(() => {
    if (userAddress) {
      const refresh = async () => {
        await refreshContractStateHasAddress();
      };
      refresh();
    }
  }, [userAddress]);

  useEffect(() => {
    if (library) {
      setContract(
        new ethers.Contract(
          process.env.REACT_APP_CONTRACT_ADDRESS,
          contractAbi,
          library.getSigner()
        )
      );
    }
  }, [library]);

  useEffect(() => {
    if (contract) {
      const init = async () => {
        setLoading(true);
        await refreshContractState();
        setLoading(false);
      };
      init();
    }
  }, [contract]);

  const refreshContractState = async () => {
    setWlPrice(await contract.wlPrice());
    setSalePrice(await contract.salePrice());
    setMaxFree((await contract.maxFree()).toNumber());
    setMaxWLTx((await contract.maxWLTx()).toNumber());
    setMaxTx((await contract.maxTx()).toNumber());
    setMaxSupply((await contract.maxSupply()).toNumber());
    setSaleState(await contract.saleState());
    setTotalSupply((await contract.totalSupply()).toNumber());
    setFreeMinted((await contract.FREE_MINTED()).toNumber());
  };

  const refreshContractStateHasAddress = async () => {
    setBalance(await contract.balanceOf(userAddress));
    setIsUserInWhitelist(whitelist.contains(userAddress));
    setClaimed(await contract.CLAIMED(userAddress));
    console.log(whitelist.getMerkleTree().getHexRoot());
  };

  const refreshStateAfterMint = async () => {
    setSaleState(await contract.saleState());
    setTotalSupply((await contract.totalSupply()).toNumber());
    setFreeMinted((await contract.FREE_MINTED()).toNumber());
    setBalance(await contract.balanceOf(userAddress));
    setClaimed(await contract.CLAIMED(userAddress));
    setMintSuccess(true);
  };

  const connectWallet = async () => {
    await activate(
      injected,
      async (error) => {
        const network = config.unsupportedChainSetup[config.chainId];
        handleNetworkSwitch("base");
        const hasSetup = await switchChain(
          network ?? {
            chainId: `0x${parseInt(config.chainId.toString()).toString(16)}`,
          }
        );
        if (hasSetup) {
          await activate(injected, async () => {
            console.log(`${error.message}`);
          });
        } else {
          alert(`Unable to connect to required network ${config.chainId}`);
        }
      },
      false
    );
  };

  const mintTokens = async (amount, price) => {
    try {
      setLoading(true);
      let transaction;
      if (isUserInWhitelist && saleState === 1) {
        const merkleProof = whitelist.getProofForAddress(userAddress);
        transaction = await contract.whitelistMint(amount, merkleProof, {
          value: ethers.utils.parseUnits(price, "ether"),
        });
      } else {
        transaction = await contract.publicMint(amount, {
          value: ethers.utils.parseUnits(price, "ether"),
        });
      }
      setBtnDisabled(true);
      setLoading(false);
      await transaction.wait();
      await refreshStateAfterMint();
      setBtnDisabled(false);
    } catch (e) {
      console.log(e);
      alert(
        "An error occurred during the transaction. \n\nPlease check and ensure you have sufficient Gas and funds to complete this transaction.\n\nTry reconnecting or switching to a different wallet, then refresh the page to proceed with the transaction."
      );
      setLoading(false);
      setBtnDisabled(false);
    }
  };

  return (
    <div>
      {loading ? <Loading /> : null}
      {mintSuccess && (
        <div className="modal-background">
          <div className="modal-content">
            <h2>Congratulations</h2>
            <b>Minted !</b>
            <button
              className="testbutton"
              onClick={() => setMintSuccess(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <MintWidget
        userAddress={userAddress}
        wlPrice={wlPrice}
        salePrice={salePrice}
        maxFree={maxFree}
        maxWLTx={maxWLTx}
        maxTx={maxTx}
        maxSupply={maxSupply}
        freeMinted={freeMinted}
        totalSupply={totalSupply}
        claimed={claimed}
        saleState={saleState}
        balance={balance}
        loading={loading}
        isUserInWhitelist={isUserInWhitelist}
        isWalletConnected={active}
        disabled={btnDisabled}
        mintTokens={(mintAmount, price) => mintTokens(mintAmount, price)}
        connectWallet={() => connectWallet()}
        disconnectWallet={() => deactivate()}
      />
    </div>
  );
};

export default MintTemplate;

import React, { useState, useRef, useEffect } from "react";
import "../game.css";
import { useNavigate } from "react-router-dom";
import { callApi } from "./api";
import { formatScore } from "../utils/helpers";
import Confetti from "react-confetti";
import "../index.css";

const Game = () => {
  const navigate = useNavigate();
  const [score, setScore] = useState(0);
  const [coin, setCoin] = useState(0);
  const [gameRunning, setGameRunning] = useState(false);
  const [ballPosition, setBallPosition] = useState({
    left: "0px",
    top: "0px",
  });
  const gameTimeout = useRef(null);
  const scoreRef = useRef(0);
  const audioRef = useRef();
  const [seconds, setSeconds] = useState(44);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    console.log(gameRunning);
    let intervalId;

    if (gameRunning && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [gameRunning, seconds]);
  const progressBarStyle = {
    width: `${(seconds / 45) * 100}%`,
    height: "10px",
    backgroundColor: "#fdc252",
    backgroundSize: "10px 10px",
  };

  const handleClick = () => {
    if (gameRunning) {
      setScore(score + 10000);
      scoreRef.current = scoreRef.current + 10000;
      setBallPosition({
        left: Math.floor(Math.random() * (window.innerWidth - 100)) + "px",
        top: Math.floor(Math.random() * (window.innerHeight - 100)) + "px",
      });
    }
  };

  useEffect(() => {
    const currentTimeStamp = new Date().getTime();
    console.log(currentTimeStamp);
    if (gameRunning) {
      const urlParams = new URLSearchParams(window.location.search);
      gameTimeout.current = setTimeout(() => {
        const wallet = urlParams.get("wallet");
        const discord = decodeURIComponent(urlParams.get("discordID"));
        setCoin(0);
        setGameRunning(false);
        const param = {
          discord_id: discord,
          score: `${scoreRef.current}`,
          wallet,
        };

        const fetchData = async () => {
          try {
            const data = await callApi(
              "POST",
              `${process.env.REACT_APP_END_POINT}/send-score`,
              param
            );
            console.log(data);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
        setIsModalOpen(true);
      }, 45000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameRunning]);

  const handleStart = () => {
    audioRef.current.play();
    setScore(0);
    setBallPosition({
      left: Math.floor(Math.random() * (window.innerWidth - 100)) + "px",
      top: Math.floor(Math.random() * (window.innerHeight - 100)) + "px",
    });
    setCoin(1);
    setGameRunning(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  return (
    <div className="game-play">
      <div style={progressBarStyle}></div>
      <audio autoplay loop preload="auto" ref={audioRef}>
        <source src="/music/sound.mp3" type="audio/mpeg"></source>
      </audio>
      <>
        {coin === 1 ? (
          <>
            <div
              className="z-2 transform hover:scale-125 transition duration-300 ease-in-out"
              style={{
                position: "absolute",
                ...ballPosition,
              }}
              onClick={handleClick}
            >
              <img
                style={{ maxWidth: "5vw", maxHeight: "5vh" }}
                src="/images/coin.webp"
                alt="coin"
              />
            </div>
          </>
        ) : (
          ""
        )}
      </>
      <div className="scoreboard z-1">Score: {formatScore(score)}</div>
      <>
        {gameRunning === false ? (
          <>
            <button className="start-button testbutton" onClick={handleStart}>
              Start
            </button>
          </>
        ) : (
          ""
        )}
      </>
      {isModalOpen && (
        <>
          <div className="modal-background">
            <div className="modal-content">
              <h2>Game Over</h2>
              <p>You got {formatScore(score)} $Pipi</p>
              <button className="start-button testbutton" onClick={closeModal}>
                Go home
              </button>
            </div>
          </div>
          <Confetti />
        </>
      )}
    </div>
  );
};

export default Game;

import React, { StrictMode } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import Menu from "../components/Menu";
import MintTemplate from "../components/Mint/MintTemplate";
import "../App.css";
const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};
const Mint = () => {
  return (
    <div>
      <Menu></Menu>
      <div className="mint-page">
        <StrictMode>
          <Web3ReactProvider getLibrary={getLibrary}>
            <MintTemplate />
          </Web3ReactProvider>
        </StrictMode>
      </div>
    </div>
  );
};

export default Mint;

import React, { useState } from "react";
import styles from "../styles/walletCheck.module.css";
import axios from "axios";
import Menu from "../components/Menu";
import "../App.css";

const WalletChecker = () => {
  const [wallet, setWallet] = useState("");
  const [isModalSelected, setIsModalSelected] = useState(false);
  const [isModalNotSelected, setIsModalNotSelected] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsModalSelected(false);
    setIsModalNotSelected(false);
    try {
      const formData = new FormData();
      formData.append("wallet", wallet);
      axios
        .post(`${process.env.REACT_APP_END_POINT}/check-wallet`, formData)
        .then((response) => {
          //   console.log(response.data.data);

          if (response.data.status === true) {
            setIsModalSelected(true);
          } else {
            setIsModalNotSelected(true);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error.message);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const closeModal = () => {
    setIsModalSelected(false);
  };

  const closeModalNot = () => {
    setIsModalNotSelected(false);
  };
  return (
    <div className={styles.containerW}>
      <Menu></Menu>
      <div className={styles.WalletChecker}>
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <p>
              Pipi NFT Free Mint On April, 20th ( Base Chain) Hold 3 NFTs will
              make you VIP and get 1,000,000 $PIPI Airdrop
              <br /> <br />
              Check Your Wallet Now If You Are WL
            </p>

            <div className={styles.modalBody}>
              <form onSubmit={handleSubmit}>
                <div>
                  <div className={styles.checkPart}>
                    <input
                      type="text"
                      value={wallet}
                      onChange={(e) => setWallet(e.target.value)}
                      placeholder="0x..."
                      required
                    />
                    <button type="submit">Check</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isModalSelected && (
        <div className="modal-background">
          <div className="modal-content">
            <h2>Whitelisted</h2>
            <p>Congrats, You Are Able To Mint Free </p>

            <button className="testbutton" onClick={closeModal}>
              OK
            </button>
          </div>
        </div>
      )}

      {isModalNotSelected && (
        <div className="modal-background">
          <div className="modal-content">
            <h2>Not Whitelisted</h2>
            <p>Sorry, You Are Not On Free Mint List </p>

            <button className="testbutton" onClick={closeModalNot}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default WalletChecker;

import React from "react";
import "../styles/menu.css";

const Menu = () => {
  return (
    <div className="menu-container">
      <b className="text-logo">Pipi City</b>
      <div className="menu-tabs">
        <a href="/">NFTs</a>
        <a href="/pipi">$Pipi</a>
        <a href="/story">Story</a>
        <a href="/game">Game</a>
        {/* <a href="/wallet-checker">WalletChecker</a> */}
        <a className="link-mint" href="/free-mint">
          Free Mint
        </a>
      </div>
    </div>
  );
};

export default Menu;

import React from "react";
import { formatAddress } from "../utils/helpers";
import styles from "../index.module.css";

const ConnectMetamasks = (props) => {
  return (
    <>
      <button
        type="button"
        className="testbutton w-full text-[#00A5F0] bg-gray-700 hover:bg-[#dedede] border-none hover:shadow-[3px_3px_8px_0_rgba(91,91,91,1)]  py-2 text-xl uppercase disabled:bg-[#B7B7B7] disabled:text-[#090909] disabled:hover:cursor-not-allowed"
        onClick={
          !props.isWalletConnected
            ? props.connectWallet
            : props.disconnectWallet
        }
      >
        <span className={`${styles.ConnectM} flex items-center justify-center`}>
          {!props.isWalletConnected ? (
            <span>Connect Wallet</span>
          ) : (
            <>
              <span className="mr-2">
                {props.userAddress && formatAddress(props.userAddress, 4)}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <line
                  x1="200"
                  y1="56"
                  x2="56"
                  y2="200"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></line>
                <line
                  x1="200"
                  y1="200"
                  x2="56"
                  y2="56"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></line>
              </svg>
            </>
          )}
        </span>
      </button>
    </>
  );
};

export default ConnectMetamasks;

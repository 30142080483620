import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Buffer } from "buffer";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import HomeGame from "./pages/HomeGame";
import Game from "./pages/Game";
import Story from "./pages/Story";
import Pipi from "./pages/Pipi";
import WalletChecker from "./pages/WalletChecker";
import Mint from "./pages/Mint";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

window.Buffer = window.Buffer || Buffer;
const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};

export default function App() {
  return (
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/game" element={<HomeGame />} />
            <Route path="/story" element={<Story />} />
            <Route path="/pipi" element={<Pipi />} />
            <Route path="/wallet-checker" element={<WalletChecker />} />
            <Route path="/free-mint" element={<Mint />} />
            <Route path="play" element={<Game />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </Web3ReactProvider>
    </StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

import React from "react";
import "../styles/about.css";
import "../App.css";

const About = () => {
  return (
    <div className="container-page bg-about">
      <div className="about-page">
        <div className="content-about">
          <b>Pipi City NFTs - Meme NFTs On Base Chain</b>
          <p>
            Welcome to Pipi City NFTs, we are just meme NFTs with the best arts
            ever! That’s it
            <br />
            Pipi City is not an investment, it is a crazy meme NFT and a ticket
            to airdrop meme coins $PIPI. Don't expect returns, expect laughs,
            happy moments, and meme vibes.
          </p>
        </div>
        <img src="/assets/images/about.gif" alt="gif about"></img>
      </div>
    </div>
  );
};

export default About;

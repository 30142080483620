import { React } from "react";
import Menu from "../components/Menu";
import Social from "../components/Social";
import FallingCoins from "../components/FallingCoins";
import "../styles/pipi.css";

const Pipi = () => {
  return (
    <div className="containe-home">
      <Menu></Menu>
      <FallingCoins></FallingCoins>
      <div className="banner-pipi">
        <img
          className="img_banner"
          src="/assets/pipi/banner_pipi.webp"
          alt="banner pipi"
        ></img>
        <img
          className="img_banner_m"
          src="/assets/pipi/banner_pipi_m.webp"
          alt="banner pipi"
        ></img>
      </div>
      <div className="ca-pipi">
        <h1>TOKENOMICS</h1>
        <p className="text-pipi">
          $PIPI is the official token of Pipi City. There won't be any presale –
          just a fancy airdrop exclusively for VIP holders. To hop on the $PIPI
          train, you gotta own at least 3 Pipi City NFTs. Consider it your
          golden ticket to $PIPI town. Let's buckle up and enjoy the $PIPI ride
          on the Lambo of joy! 🚗💨
        </p>
        <div className="ca-content">
          <img src="/assets/pipi/ca.gif" alt="ca gif"></img>
          <div>
            <div className="frame-content">
              <b>TOKEN ADDRESS</b>
              <p>Conming soon</p>
            </div>
            <div className="frame-content">
              <b>TOTAL SUPPLY</b>
              <p>Conming soon</p>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-pipi">
        <img src="/assets/pipi/chart_pipi_2.webp" alt="chart pipi"></img>
      </div>
      <div className="trade-pipi">
        <img src="/assets/pipi/trade.gif" alt="trade pipi"></img>
      </div>
      <Social></Social>
    </div>
  );
};

export default Pipi;

import React, { useEffect } from "react";
import "../styles/fallingcoin.css";

const FallingCoins = () => {
  useEffect(() => {
    const numCoins = 5; // Number of coins
    const duration = 3; // Duration of falling animation (seconds)

    for (let i = 0; i < numCoins; i++) {
      createCoin();
    }

    function createCoin() {
      const coin = document.createElement("div");
      coin.className = "coin";
      coin.style.left = Math.random() * 100 + "vw"; // Random horizontal position
      coin.style.animationDuration = Math.random() * duration + 2 + "s"; // Random falling duration
      document.body.appendChild(coin);
      coin.addEventListener("animationend", () => {
        document.body.removeChild(coin); // Remove coin when animation ends
        createCoin(); // Create a new coin
      });
    }
  }, []);

  return <div className="falling-coins"></div>;
};

export default FallingCoins;

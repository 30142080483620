export function formatAddress(value, length = 4) {
  return value
    ? `${value.substring(0, length + 1)}...${value.substring(
        value.length - length
      )}`
    : "";
}

export function formatScore(score) {
  return score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
